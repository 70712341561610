<template>
  <div id="app">
    <a name="top" id="top"><span class="sr-only">top</span></a>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    Header, Footer
  }
}
</script>
