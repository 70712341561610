<template>
  <div class="footer">
    <div class="footer-content">
      <b-container>
        <b-row align-v="center" align-h="center" cols="1" cols-sm="1" cols-md="2" cols-lg="3">
          <b-col>
            <div class="footer-link">
              <a href="https://www.forest.gov.tw/"  target="_blabk" title="農業部林業及自然保育署">
                <b-img :src="require('../assets/imgs/footer_img_forest.png')" fluid alt="" ></b-img>
                <div class="footer-link-title">{{ $t("footer-link-forest") }}</div>
              </a>
            </div>
          </b-col>
          <b-col>
            <div class="footer-link">
              <a href="https://afrch.forest.gov.tw/"  target="_blabk" title="阿里山林業鐵路及文化資產管理處">
                <b-img :src="require('../assets/imgs/footer_img_afrch.png')" fluid alt="" ></b-img>
                <div class="footer-link-title d-flex flex-column"><span>{{ $t("footer-link-afrch-1") }}</span><span>{{ $t("footer-link-afrch-2") }}</span></div>
              </a>
            </div>
          </b-col>
          <b-col>
            <div class="footer-link">
              <a href="https://wllr.org.uk/"  target="_blabk" title="威爾普蘭菲爾鐵路">
                <b-img :src="require('../assets/imgs/footer_img_W&LLR.png')" fluid alt="" ></b-img>
                <div class="footer-link-title">{{ $t("footer-link-wllr") }}</div>
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
    data() {
      return {
        visitCount: null,
        updateTime: "2022/11/22",
      };
    },
    watch: {},
    mounted() {},
    methods: {},
  };
</script>

<style lang="scss">



</style>
