// i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import EN from './lang/en.json' // 英文
import ZH from './lang/zh.json' // 繁體中文

Vue.use(VueI18n)

let browserLanguage = "EN"
//window.navigator.language

let userSetLanguage = sessionStorage.getItem("language");

// 先塞入瀏覽器預設語言在 i18n
if (userSetLanguage == null) {
    if (browserLanguage.toLowerCase() === 'en') {
        browserLanguage = 'EN'
    } else if (browserLanguage.toLowerCase() === 'zh-tw') {
        browserLanguage = 'ZH'
    } else {
        browserLanguage = 'EN'
    }
} else {
    browserLanguage = userSetLanguage;
}


// 存放語系 json 
const messages = {
    EN: EN,
    ZH: ZH
}

export const i18n = new VueI18n({
    locale: browserLanguage, // set locale
    fallbackLocale: browserLanguage,
    messages // set locale messages
})