<template>
  <div class="index">
    <section class="mainHead">
      <b-container>
        <b-row class="justify-content-center">
          <b-col>
            <img src="../assets/imgs/index_img_map.png" class="img-fluid" alt="">
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row class="justify-content-center">
          <b-col>
            <h2>{{ $t("index-main-title") }}</h2>
            <h3>{{ $t("index-sub-title") }}</h3>
            <p>{{ $t("index-content") }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div>
              <b-alert show dismissible>
                {{ $t("index-alert") }} <b>&rArr;</b>
              </b-alert>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="indexVideo">
      <b-container>
        <b-row class="justify-content-center">
          <b-col>
            <div class="video-container">
                <iframe
                src="https://www.youtube.com/embed/_0D6SSS6NEc?si=DSOoZn7V0uEu8PAo"
                title="2024「牽手762的約定」阿里山林鐵DL-34機關車台英交流大使 / A story that passes through 9971KM"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                ></iframe>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <div id="myMenu">
        <a href="javascript:" v-scroll-to="'#top'" title="回到最頂[連結]"
          ><span class="icon-arrow_up"></span
          ><span class="sr-only">to top</span></a
        >
    </div>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
  };
</script>

<style lang="scss">
  .test {
    display: none;
  }
</style>
