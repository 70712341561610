import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import mixin from './components/Mixin.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { ModalPlugin } from 'bootstrap-vue'
import { BModal, VBModal } from 'bootstrap-vue'
import './assets/layout.scss'
import VueScrollTo from 'vue-scrollto'
import '../public/assets/css/style.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { i18n } from './i18n/i18n'

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.use(BootstrapVue)
Vue.use(ModalPlugin)
Vue.component('b-modal', BModal)
Vue.directive('b-modal', VBModal)
Vue.mixin(mixin)
Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios;

axios.defaults.baseURL = "/api"
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  created() {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
