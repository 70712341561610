<template>
  <div class="header">

    <b-container fluid class="TopNavGroup">
      <div class="d-flex justify-content-between align-items-center">
      <h1 class="mainLogo">
        <b-link to="/" title="返回 The Railway Journey阿里山之道 首頁">The Railway Journey 阿里山之道</b-link>
      </h1>
      <div>
        <b-form-select
          @change="setLang"
          v-model="langSelected"
          :options="langOptions"
        ></b-form-select>
      </div>
      </div>
      <!-- <b-link to="SiteMap" class="siteMapBtn" value="網站導覽" title="網站導覽" alt="網站導覽">網站導覽</b-link> -->
      
      <!-- <b-link
        class="btn1"
        @click="navBarActive = !navBarActive"
        value="顯示導覽列"
        title="顯示導覽列"
        alt="顯示導覽列"
        ><i
          class="fa"
          :class="{ 'fa-bars': !navBarActive, 'fa-times': navBarActive }"
          aria-hidden="true"
        ></i
        ><span class="sr-only">導覽列</span></b-link
      >

      <nav class="TopNav">
        <div class="nav-items" :class="{ navSlider: navBarActive }">
          <b-container fluid>
            <ul class="nav-itemsTOP">
              <li>
                <b-link
                  to="/"
                  @click="navBarActive = !navBarActive"
                  title="返回 水保國際村 首頁"
                  >{{ $t("TopNav01") }}</b-link
                >
              </li>
              <li>
                <b-link
                  to=""
                  @click="navBarActive = !navBarActive"
                  title="前往 水鹿村:九份二山"
                  >{{ $t("TopNav02") }}</b-link
                >
              </li>
              <li>
                <b-link
                  to=""
                  @click="navBarActive = !navBarActive"
                  title="前往 茶葉村:瑞里幼葉林"
                  >{{ $t("TopNav03") }}</b-link
                >
              </li>
              <li>
                <b-link
                  to=""
                  @click="navBarActive = !navBarActive"
                  title="前往 咖啡村:古坑華山"
                  >{{ $t("TopNav04") }}</b-link
                >
              </li>
              <li>
                <b-link
                  to=""
                  @click="navBarActive = !navBarActive"
                  title="前往 國際村影音"
                  >{{ $t("TopNav05") }}</b-link
                >
              </li>
              <li>
                <b-link
                  to="story"
                  @click="navBarActive = !navBarActive"
                  title="前往 國際村小故事"
                  >{{ $t("TopNav06") }}</b-link
                >
              </li>
              <li><b-form-select
                  @change="setLang"
                  v-model="langSelected"
                  :options="langOptions"
                ></b-form-select>
              </li>
            </ul>
          </b-container>
        </div>
      </nav> -->
    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        navBarActive: false,
        langSelected: "EN",
        langOptions: [
          {
            value: "EN",
            text: "English",
          },
          {
            value: "ZH",
            text: "繁體中文",
          },
        ],
      };
    },
    mounted() {
      if (sessionStorage.getItem("language") != null) {
        this.langSelected = sessionStorage.getItem("language");
      }
    },
    watch: {},
    methods: {
      // 儲存切換的語系
      setLang() {
        sessionStorage.setItem("language", this.langSelected);
        location.reload();
      },
    },
  };
</script>

<style lang="scss">
  .header {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);

    a {
      &.Go-content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: #ffbf47;
        color: #000;
        height: 0;
        overflow: hidden;
        -webkit-transition: top 1s ease-out;
        transition: top 1s ease-out;
        z-index: 9999;

        &:focus {
          height: auto;
          padding: 1rem;
          overflow: none;
        }
      }
    }

    .TopNavGroup {
      position: relative;

      .mainLogo {
        z-index: 9998;

        a {
          background-image: url(../assets/imgs/logo.png);
          background-repeat: no-repeat;
          background-position: center;
          display: block;
          width: 25rem;
          height: 70px;
          background-size: contain;
          font: 0/0 a;
          color: transparent;
          z-index: 9998;
          position: relative;
        }
      }

      .siteMapBtn {
        width: 70px;
        height: 70px;
        display: block;
        background-color: transparent;
        background-image: url(../assets/imgs/sitemap.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        font-size: 0rem;
        text-indent: -9999px;
        overflow: hidden;
        position: absolute;
        right: 5rem;
        top: 1rem;
      }
    }

    nav {
      display: flex;
      text-align: center;
      font-size: 3rem;
    }

    .nav-items {
      font-size: 2rem;
      list-style: none;
      margin: 0 auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      font-weight: 500;
      width: 100%;
      // background-image: url(../assets/imgs/innerPageBg.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #fff;
      z-index: 1;
      position: absolute;
      display: none;
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
      -o-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
      -webkit-filter: drop-shadow(5px 5px 5spx rgba(0, 0, 0, 0.2));

      li {
        display: inline-block;
      }

      .nav-itemsTOP {
        padding: 1rem 0;

        li {
          a {
            color: #123e54;
            margin: 0 1.5rem;
            padding: 0.5rem 0;
            overflow: hidden;
            position: relative;

            &:before {
              left: 0;
              bottom: 0;
              width: 100%;
              height: 4px;
              background: #123e54;
              content: "";
              position: absolute;
              transform: scaleX(0);
              transition: transform 0.5s ease;
            }

            &:hover {
              &:before {
                transform: scaleX(1);
              }
            }
          }
        }
      }

      .sub-link {
        margin: 0;

        li {
          padding: 0.5rem;

          a {
            display: inline-block;
            position: relative;
            width: 60px;
            height: 60px;
            color: #fff;
            border-radius: 100px;
            background-color: #fff;
            border: 3px solid #cb9c7a;

            img {
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
            }

            .mymenu_subtitle {
              padding-top: 0.7rem;
              font-size: 0.5rem;
              line-height: 1rem;
            }

            &:hover {
              background-color: #cb9c7a;

              img {
                -webkit-transition: opacity 0.2s ease-in-out;
                -moz-transition: opacity 0.2s ease-in-out;
                -o-transition: opacity 0.2s ease-in-out;
                transition: opacity 0.2s ease-in-out;
                opacity: 0;
              }
            }
          }
        }
      }
    }

    h2 {
      margin: auto;
      z-index: 3;
      color: #123e54;
      transform: translate(-40px);
    }

    .btn1 {
      padding: 0;
      margin: 0;
      z-index: 9999;
      font-size: 3rem;
      position: absolute;
      right: 1rem;
      top: 0;

      i {
        color: #123e54;
      }
    }

    // @keyframes slide {
    //   0% {
    //     right: 140%;
    //   }

    //   100% {
    //     right: 0;
    //   }
    // }

    .navSlider {
      // animation-name: slide;
      // animation-duration: 0.4s;
      // animation-iteration-count: 1;
      display: block;
    }
  }

  @media (min-width: 1920px) {
    .navSlider {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (min-width: 1025px) {
    .header {
      top: 0;
      z-index: 9998;
      position: absolute;

      .navSlider {
        height: 30vh;
        padding-top: 6rem;
      }
    }
  }

  @media (max-width: 1024px) {
    .header {
      position: fixed;
      z-index: 9998;
      top: 0;
      background-color: #153727;
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
      -o-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
      -webkit-filter: drop-shadow(5px 5px 5spx rgba(0, 0, 0, 0.2));

      .TopNavGroup {
        .mainLogo {
          a {
            width: 18rem;
          }
        }

        .siteMapBtn {
          width: 35px;
          height: 35px;
          right: 3rem;
          top: 1.4rem;
        }
      }

      .navSlider {
        padding-top: 5rem;
      }

      .nav-items {
        height: 100vh;

        li {
          display: block;
          margin-bottom: 0.5rem;
        }

        .sub-link {
          li {
            display: inline-block;
          }
        }
      }

      .btn1 {
        font-size: 2rem;
        right: 1rem;
        top: 1rem;
      }
    }
  }

  @media (max-width: 641px) {
    .header {
      .TopNavGroup {
        .mainLogo {
          a {
            width: 15rem;
          }
        }
      }
      nav {
        font-size: 2.5rem;

        i {
          margin-top: 20px;
        }
      }
    }
  }
</style>
